import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Lan } from '../../assets/header/Lan.svg';
import { ReactComponent as Menu } from '../../assets/header/Menu.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules';
import ToastPopup from './ToastPopup';
import { NotoSans17 } from '../../styles/fontStyls';
import MenuComponent from './Menu';
import { ClientNameProps, StyleThemeProps } from '../../types';

const Header = () => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { header } = useSelector((state: RootState) => ({
    header: state.defaults.name,
  }));
  const [errorState, setErrorState] = useState(false);
  const [menuState, setMenuState] = useState(false);

  const titleHeader = () => {
    return <Title clientname={clientName}>{header}</Title>;
  };
  return (
    <HeaderWrap clientname={clientName}>
      {menuState ? (
        <MenuComponent
          state={menuState}
          setState={setMenuState}
        ></MenuComponent>
      ) : (
        ''
      )}
      {clientName === 'defaults' ? (
        <MenuBtn
          onClick={() => {
            setMenuState(true);
          }}
        />
      ) : (
        <div />
      )}

      <CompanyWrap
        clientname={clientName}
        onClick={() => {
          window.location.href = `${window.location.origin}${window.location.pathname}`;
        }}
      >
        {clientName !== 'jejucom' && (
          <HomeImg
            src={`https://chatbot-front.s3.ap-northeast-2.amazonaws.com/${clientName}/header/Title.png`}
            clientname={clientName}
          />
        )}
        {titleHeader()}
      </CompanyWrap>
      {clientName === 'defaults' ? (
        <LanBtn
          onClick={() => {
            setErrorState(true);
            setTimeout(() => {
              setErrorState(false);
            }, 1000);
          }}
        />
      ) : (
        <div />
      )}

      {errorState ? <ToastPopup /> : ''}
    </HeaderWrap>
  );
};

export default Header;

const HeaderWrap = styled.div<StyleThemeProps>`
  width: 375px;
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${({ theme, clientname }) =>
    theme[clientname].header.wrap?.boxShadow ||
    '0 6px 4px 0 rgb(46 47 48 / 4%)'};
  background: ${({ theme, clientname }) =>
    theme[clientname].header.wrap.background};
  border-bottom: ${({ theme, clientname }) =>
    theme[clientname].header.wrap?.borderBottom || 'none'};
  z-index: 1000;
  position: fixed;
  top: 0;
`;
const CompanyWrap = styled.button<StyleThemeProps>`
  height: 25px;
  display: flex;
  align-items: center;
  border: 0px;
  background: ${({ theme, clientname }) =>
    theme[clientname].header.company.background};
  border-radius: ${({ theme, clientname }) =>
    theme[clientname].header.company.borderRadidus};
`;
const MenuBtn = styled(Menu)`
  margin-left: 18px;
`;
const LanBtn = styled(Lan)`
  margin-right: 18px;
`;
const Title = styled.div<StyleThemeProps>`
  ${NotoSans17}
  color:${({ theme, clientname }) => theme[clientname].header.title.color};
  margin-left: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.marginLeft || '12px'};
  margin-bottom: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.marginBottom || '0'};
  font-size: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.fontSize};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.position};
  top: ${({ theme, clientname }) => theme[clientname].header?.title?.top};
  left: ${({ theme, clientname }) => theme[clientname].header?.title?.left};
  cursor: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.cursor || 'default'};
`;
const Title2 = styled.div<StyleThemeProps>`
  ${NotoSans17}
  color:${({ theme, clientname }) => theme[clientname].header?.title2.color};
  margin-left: 5px;
  font-size: ${({ theme, clientname }) =>
    theme[clientname].header?.title2?.fontSize};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.position};
  top: ${({ theme, clientname }) => theme[clientname].header?.title2?.top};
  left: ${({ theme, clientname }) => theme[clientname].header?.title2?.left};
`;
const Title3 = styled.div<StyleThemeProps>`
  ${NotoSans17}
  color:${({ theme, clientname }) => theme[clientname].header?.title3.color};
  font-size: ${({ theme, clientname }) =>
    theme[clientname].header?.title3?.fontSize};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.title3?.position};
  top: ${({ theme, clientname }) => theme[clientname].header?.title3?.top};
  left: ${({ theme, clientname }) => theme[clientname].header?.title3?.left};
`;
const TitleSpan = styled.div<StyleThemeProps>`
  ${NotoSans17}
  color:${({ theme, clientname }) => theme[clientname].header?.titlespan.color};
  font-size: ${({ theme, clientname }) =>
    theme[clientname].header?.titlespan?.fontSize};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.titlespan?.position};
  top: ${({ theme, clientname }) => theme[clientname].header?.titlespan?.top};
  left: ${({ theme, clientname }) => theme[clientname].header?.titlespan?.left};
`;
const HomeImg = styled.img<StyleThemeProps>`
  path {
    fill: ${({ theme, clientname }) => theme[clientname].header.title.color};
  }
  max-height: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.maxHeight};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.position};
  left: ${({ theme, clientname }) => theme[clientname].header?.img?.left};
`;
