// eslint-disable-next-line import/no-anonymous-default-export
export default {
  jardin: 'z3Rq2v-XCAqCSpK_MzpAQDk2CcHY2mRm',
  'jardin-test': 'RBRhh3I6NgeYGnR3vPSTDvXc_HWXw0I2',
  mdtpcb: 'IJAdq-1x1xhMBEYA0lVHe0nmKOpXOYkt',
  'mdtpcb-test': 'H30aDC1s8PP3jpXx2wJyvrNHay4Ywyqy',
  gjto: 'uvliYWrAboUP1Musidov-h0umJZsXBOF',
  'gjto-test': '67OBfkJl1zSygQY4PuqGpt-2S3ogzcjv',
  nextstory: '5WzgvEMFQsAu7H86w1oJ7e_3rxTg-fdi',
  'nextstory-test': '1hyHjXZLMhenXce5KMGMyGoyg-LybyhY',
  xcrew: 'cxeo1sLisUQqvb9ekaXvcRs4bX9krRRH',
  xcrewinfo: 'Fmu0VQKn4uXdFUMmZfMwwhgZcFdDRIw2',
  kfo: 'M5IttTXXff-NBsSwhyZLM1rD4uOtfEmL',
  'kfo-test': '-BgqnfT6YPVeB0bggtBYTFhCfvUyRWDu',
  kfowork: 'a05pkh7QBMwhKElvtjmvHk1d3JDul9ah',
  'kfowork-test': '__HKkdY0MLM70mF_WMBdJDsNL3XKfBr3',
  kfoedu: 'PVgCs01-Ny83joo0z64ZIwPL1ij-eDEh',
  'kfoedu-test': 'G5Z8xaWyguIau0SK5FXCioeuII3aqhPj',
  'sunil2021-1': 'U_Zf0qLjrEFtANF_gCL94-__cvUKWsDa',
  'sunil2021-2': 'DkrEu7iHlNby-0hMGSTMUZX_hOfWIkqK',
  smartig: 'flxuqKjEshwF2qaT0EPN5t1-PfVeIIhg',
  sample1: 'bRG60pca_EWT8ckTBiAr_BBtThV-QM2M',
  case1: 'iYngsAbTWzo3YhaHoTRW61Cso808deSg',
  'dept-bc': 'Kb3faKZDIgNbhidFozQreNgefJIyBikA',
  pspfnd: 'FpjxJ6EmLadyIS2qoOc-aAHdRybh9Hp2',
  'pspfnd-test': 'uuI0-4TRsuY9pw8qEehxNJsF2PvjxWho',
  focu: 'ExyvTxtX88gV9-e8I4EiiU71zOjG0QOy',
  debc: 'Qk2o6aZzcqCJu7fkIE2KDd4-MtHrGBz3',
  investseoul: 'lcBYYO0pPa9Xm87uxs4axeDJTIBmy6Ma',
  kbstar: '33UGjT6S0fwdGWKf-cFsn0ecwLnsTVTA',
  'kbstar-test': '33UGjT6S0fwdGWKf-cFsn0ecwLnsTVTA',
  seocho: 'UgkZlcDqZ37B3Xo7VH1MC9J-GS6POzY0',
  'seocho-test': 'RBRhh3I6NgeYGnR3vPSTDvXc_HWXw0I2',
  visitjeju: 'Dy_92GUNTOm-J_D6eMZKij7k-mz9aUYM',
  'visitjeju-test': 'RBRhh3I6NgeYGnR3vPSTDvXc_HWXw0I2',
  jejucom: 'Wur0_f2TYsf-CWsIm36SVPX9WnhCY3Ms',
  'jejucom-test': 'RBRhh3I6NgeYGnR3vPSTDvXc_HWXw0I2',
  sscb: 'OgSpAHQ1yrGIASy3l0wN0ci5ok7f0T2X',
  'sscb-test': 'RBRhh3I6NgeYGnR3vPSTDvXc_HWXw0I2',
  sba:'Mr6VfD9yyofHeUs7aYfOHoYjJtOalI_b',
  'sba-test':'RBRhh3I6NgeYGnR3vPSTDvXc_HWXw0I2'
};
